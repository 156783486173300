import React, { useEffect } from 'react';
import { LayoutMainMapRightSide } from '@bvt-features/mainmap/mainmap/component/LayoutMainMapRightSide';
import { useMap } from 'react-leaflet';
import { useMainMap } from '@bvt-features/mainmap/mainmap/hook/useMainMap';
import DynamicIcon from '@bvt-icons';
import './PertaminaDetailContainer.scss';
import { AssetItem } from '@bvt-features/pertamina/components/AssetItem';
import { TableRecommendation } from '@bvt-features/pertamina/components/TableRecommendation';
import columns from '@bvt-features/pertamina/constant/COLUMN_RECOMENDATION';
import { usePertaminaAsset } from '@bvt-features/pertamina/hooks/usePertaminaAsset';
import _ from 'lodash';
import { Loading } from '@bvt-atoms';
import { useNavigate } from 'react-router';

const Loader = () => {
  return (
    <div className='loader'>
      <Loading color='green' size='medium' />
    </div>
  );
};

export const PertaminaDetailContainer = () => {
  const initialmap = useMap();
  const mainMap = useMainMap();
  const asset = usePertaminaAsset();
  const navigate = useNavigate();
  const site_id = asset.state.data.list.selectedID;
  const detail = asset?.state?.data?.detail?.data;
  const properties = detail?.properties;
  const projectInfo = properties?.project_information || [];

  function getValueByAttribute(data, attribute) {
    const item = data.find((item) => item.Attribute === attribute);
    return item ? item.Value : null;
  }

  const transformDataTable = () => {
    const redOcean = properties?.red_ocean?.map((item, key) => ({
      key: `red-ocean-${key}`,
      category: item?.Pertamina_Category,
      subCategory: item?.['Pertamina_Sub-Category'],
      bvtCategory: item?.BVT_Category,
      actual: item?.Actual,
      expected: item?.Expected,
      gap: item?.Gap,
    }));
    const bluedOcean = properties?.blue_ocean?.map((item, key) => ({
      key: `red-ocean-${key}`,
      category: item?.Pertamina_Category,
      subCategory: item?.['Pertamina_Sub-Category'],
      bvtCategory: item?.BVT_Category,
      actual: item?.Actual,
      expected: item?.Expected,
      gap: item?.Gap,
    }));

    return {
      redOcean,
      bluedOcean,
    };
  };

  const transformDataProjectInfo = () => {
    const name = getValueByAttribute(projectInfo, 'Name');
    const coordinate = getValueByAttribute(projectInfo, 'Coordinate').split(
      ','
    );
    const province = getValueByAttribute(projectInfo, 'Province');
    const city = getValueByAttribute(projectInfo, 'City');
    const district = getValueByAttribute(projectInfo, 'District');
    const village = getValueByAttribute(projectInfo, 'Village');
    const address = getValueByAttribute(projectInfo, 'Address');
    return {
      id: detail.id,
      name: name,
      coordinate: {
        lat: coordinate[0],
        lng: coordinate[1],
      },
      administrative: [province, city, district, village],
      image_url: asset.state.data.list.selectedImg,
      address: address,
    };
  };

  const internalDisableHandleMapEvent = (param) => {
    if (param) {
      initialmap.scrollWheelZoom.disable();
      initialmap.doubleClickZoom.disable();
      initialmap.dragging.disable();
    } else {
      initialmap.scrollWheelZoom.enable();
      initialmap.doubleClickZoom.enable();
      initialmap.dragging.enable();
    }
  };

  const handleClose = () => {
    mainMap.setActiveMenu(undefined);
    asset.setSelectedID('');
  };

  const handleDashboard = () => {
    navigate(`/app/dashboard/pertamina/${site_id}`);
  };

  useEffect(() => {
    if (site_id) {
      asset.getDetailAssetPertamina({ site_id });
    }
  }, [site_id]);

  return (
    <LayoutMainMapRightSide
      height={'100vh'}
      isActive={mainMap.state.activeMenu === 'DETAIL_ASMAN_PERTAMINA'}
      onMouseEnter={() => internalDisableHandleMapEvent(true)}
      onMouseLeave={() => internalDisableHandleMapEvent(false)}
      top='8rem'
      width={mainMap.state.activeMenuWidth}
    >
      <div className='PertaminaAssetDetail'>
        <div className='header-sidebar'>
          <span className='title'>Asset Detail</span>
          <button className='button' onClick={handleClose}>
            <DynamicIcon icon='close' />
          </button>
        </div>
        <div className='content-container'>
          {asset.state.status_DETAIL_ASSET === 'LOADING' && <Loader />}
          {asset.state.status_DETAIL_ASSET === 'SUCCESS' &&
            !_.isEmpty(detail) && (
            <div className='content'>
              <AssetItem data={transformDataProjectInfo()} type='vertical' />
              <TableRecommendation
                columns={columns}
                data={transformDataTable().redOcean}
                isTitle={true}
                note={detail?.note?.note_red_ocean || ''}
                type='Red Ocean'
              />
              <TableRecommendation
                columns={columns}
                data={transformDataTable().bluedOcean}
                isTitle={true}
                note={detail?.note?.note_blue_ocean || ''}
                type='Blue Ocean'
              />
            </div>
          )}
        </div>
        <div className='footer-sidebar'>
          <button className='button close' onClick={handleClose}>
            Close
          </button>
          <button className='button dashboard' onClick={handleDashboard}>
            Dashboard
          </button>
        </div>
      </div>
    </LayoutMainMapRightSide>
  );
};
