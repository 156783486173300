import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@bvt-atoms';
import DynamicIcon, { IconClose } from '@bvt-icons';

/**
 * @version 1.2
 * @copyright PT Bhumi Varta Technology
 * @memberof molecules
 * @author Renta <renta.yustika@bvarta.com>
 * @param {Object} props
 * @param {Object} props.body Object handler
 * @param {JSX.Element} props.body.desc set body as react element
 * @param {String} props.body.desc set text of modal body
 * @param {String} props.body.icon set icon for modal body
 * @param {String} props.body.title set title for modal body
 * @param {String} props.body.titleClass set title classname for modal body
 * @param {String} props.cancelText set the label of cancel button
 * @param {Boolean} props.footer footer modal is shown optionally
 * @param {Object} props.footerButton Object handler
 * @param {String} props.footerButton.leftClass set the label of left button
 * @param {String} props.footerButton.rightClass set the label of right button
 * @param {Object} props.handler Object handler
 * @param {Function} props.handler.onHide Object handler
 * @param {Function} props.handler.onSubmit Object handler
 * @param {Boolean} props.header header modal is shown optionally
 * @param {String} props.headerText title of the modal
 * @param {Boolean} props.headerClose boolean to show or close button popup in header
 * @param {Boolean} props.show boolean to show or hide popup
 * @param {String} props.submitText set the label of submit button
 * @param {'default' | 'logoUp'} props.type set the label of submit button
 * @param {string} props.iconHeight set the Height icon
 * @param {string} props.iconWidth set the Width icon
 */

export default function Popup(props) {
  let cancelButton, submitButton;
  if (props.cancelButton) {
    cancelButton = (
      <Button
        className={
          'popup__footer__button--left ' + props.footerButton?.leftClass
        }
        onClick={props.handler?.onHide}
      >
        {props.cancelText}
      </Button>
    );
  } else {
    cancelButton = '';
  }

  if (props.submitButton) {
    submitButton = (
      <Button
        className={
          'popup__footer__button--right ' + props.footerButton?.rightClass
        }
        onClick={props.handler?.onSubmit}
      >
        {props.submitText}
      </Button>
    );
  } else {
    submitButton = '';
  }

  if (!props.show) {
    return null;
  }
  return (
    <div className={`popup ${props.className}`} onClick={props.handler?.onHide}>
      <div className='popup__content' onClick={(e) => e.stopPropagation()}>
        {props.header && (
          <div className='popup__header'>
            <div className='popup__header--left'>{props.headerText}</div>
            {props.headerClose && (
              <IconClose background='white' onClick={props.handler?.onHide} />
            )}
          </div>
        )}
        <div className='popup__body'>
          {props.children ? (
            props.children
          ) : (
            <>
              {props.type === 'logoUp' && (
                <>
                  <div className='popup__body__icon'>
                    <DynamicIcon
                      height={
                        props.body?.iconHeight === undefined
                          ? '4.875rem'
                          : props.body?.iconHeight
                      }
                      icon={props.body?.icon}
                      width={
                        props.body?.iconWidth === undefined
                          ? '3.75rem'
                          : props.body?.iconWidth
                      }
                    />
                  </div>
                  <p className={'popup__body__title ' + props.body?.titleClass}>
                    {props.body?.title}
                  </p>
                </>
              )}
              {props.type === 'default' && (
                <>
                  <p className={'popup__body__title ' + props.body?.titleClass}>
                    {props.body?.title}
                  </p>
                  <div className='popup__body__icon'>
                    {typeof props.body?.icon === 'string' ? (
                      <DynamicIcon
                        height={'4.875rem'}
                        icon={props.body?.icon}
                        width={'3.75rem'}
                      />
                    ) : (
                      <div style={{ padding: '1.5rem 0 0.75rem' }}>
                        {props.body?.icon}
                      </div>
                    )}
                  </div>
                </>
              )}

              <div className='popup__body__text'>
                <span>{props.body?.desc}</span>
              </div>
            </>
          )}
        </div>
        {props.footer ? (
          <div className='popup__footer'>
            {cancelButton}
            {submitButton}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

Popup.defaultProps = {
  body: {},
  cancelButton: true,
  cancelText: 'Cancel',
  children: null,
  className: '',
  footer: true,
  footerButton: {
    leftClass: '',
    rightClass: '',
  },
  handler: {},
  header: true,
  headerClose: true,
  headerText: '',
  show: false,
  submitButton: true,
  submitText: 'Submit',
  type: 'default',
};

Popup.propTypes = {
  body: PropTypes.shape({
    desc: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    icon: PropTypes.string,
    iconHeight: PropTypes.string,
    iconWidth: PropTypes.string,
    title: PropTypes.string,
    titleClass: PropTypes.string,
  }),
  cancelButton: PropTypes.bool,
  cancelText: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  footer: PropTypes.bool,
  footerButton: PropTypes.shape({
    leftClass: PropTypes.string,
    rightClass: PropTypes.string,
  }),
  handler: PropTypes.shape({
    onHide: PropTypes.func,
    onSubmit: PropTypes.func,
  }),
  header: PropTypes.bool,
  headerClose: PropTypes.bool,
  headerText: PropTypes.string,
  show: PropTypes.bool,
  submitButton: PropTypes.bool,
  submitText: PropTypes.string,
  type: PropTypes.oneOf(['default', 'logoUp']),
};
