/* eslint-disable consistent-return */
import { Popup } from '@bvt-atoms';
import { useMainMap } from '@bvt-features/mainmap/mainmap/hook/useMainMap';
import DynamicIcon from '@bvt-icons';
import { PopupModules } from '@bvt-organisms';
import { Popover } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { LIST_MENU_BAR } from '../../constant/LIST_MENU_BAR';
import AboutModal from '../AboutModal/AboutModal';
import './MenuList.scss';

export default function MenuList() {
  const [visibility, setVisibility] = useState(false);
  const [menu, setMenu] = useState(LIST_MENU_BAR[0]);
  const [popupModule, setPopupModule] = useState(false);
  const [popupAbout, setPopupAbout] = useState(false);

  const MAINMAP = useMainMap();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fullscreen = useSelector(
    (state) => state.interactive.MAP.FULL_SCREEN.value
  );
  const { TILE_LAYER } = useSelector((state) => state.interactive.MAP);
  const { tileLayer } = TILE_LAYER;
  const setItems = (payload) => {
    dispatch({ type: 'MapAsideRight/setItems', payload });
  };
  const setToggle = (payload) => {
    dispatch({ type: 'MapAsideRight/setToggle', payload });
  };
  const setSlug = (payload) => {
    dispatch({ type: 'MapAsideRight/setSlug', payload });
  };

  const renderSwitchAction = (code) => {
    switch (code) {
      case 'createNewProject':
        createNewProject();
        break;
      case 'openFullscreen':
        handleOpenFullscreen();
        break;
      case 'setLightMode':
        setLightMode();
        break;
      case 'toggleOnOpacityLayer':
        MAINMAP.setActiveMenu('LAYERING_VISIBLITY');
        toggleOnOpacityLayer();
        break;
      case 'ToggleOnBaseLayer':
        MAINMAP.setActiveMenu('BASEMAP');
        ToggleOnBaseLayer();
        break;
      case 'openHelpBvarta':
        openHelpBvarta();
        break;
      case 'showAbout':
        showAbout();
        break;
      case 'openBvartaSite':
        openBvartaSite();
        break;
      case 'openRecentProject':
        navigate('/app/main/open');
        break;
      default:
        return;
    }
  };

  const createNewProject = () => {
    setPopupModule(!popupModule);
  };

  const handleOpenFullscreen = () => {
    document.documentElement.requestFullscreen();
    dispatch({ type: 'SET_FULLSCREEN', payload: !fullscreen });
  };

  const setLightMode = () => {
    const tileLayerState = {
      url: 'https://api.mapbox.com/styles/v1/mapbox/light-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw',
      mapMode: 'defaultSetting',
      title: 'Light',
    };
    const setTileLayer = (payload) => {
      dispatch({ type: 'TILE_LAYER/SET/TILE_LAYER', payload });
    };

    if (tileLayer.mode !== tileLayerState.mapMode) {
      setTileLayer({
        url: tileLayerState.url,
        title: tileLayerState.title,
        mode: tileLayerState.mapMode,
      });
    }
  };

  const toggleOnOpacityLayer = () => {
    setItems([
      { icon: 'layer', text: 'Base map', slug: 'base_map' },
      { icon: 'setting', text: 'Layer visibility', slug: 'layer_visibility' },
    ]);
    setSlug('layer_visibility');
    setToggle(true);
  };

  const ToggleOnBaseLayer = () => {
    setItems([
      { icon: 'layer', text: 'Base map', slug: 'base_map' },
      { icon: 'setting', text: 'Layer visibility', slug: 'layer_visibility' },
    ]);
    setSlug('base_map');
    setToggle(true);
  };

  const openHelpBvarta = () => {
    window.open(
      'https://api.whatsapp.com/send?phone=6287779077750&text=(Web)%20Hello%20Bvarta%20Team.',
      '_blank',
      'noopener,noreferrer'
    );
  };

  const showAbout = () => {
    setPopupAbout(true);
  };

  const openBvartaSite = () => {
    window.open('https://bvarta.com/', '_blank', 'noopener,noreferrer');
  };

  const handleAboutPopup = {
    onHide: () => setPopupAbout(false),
    onSubmit: () => setPopupAbout(false),
  };

  const ListSubmenu = (
    <div className='menu-bar__menu-list__submenu'>
      <ul className='menu-bar__menu-list__submenu__list'>
        <PopupModules
          handleCancel={() => setPopupModule(!popupModule)}
          setShow={setPopupModule}
          show={popupModule}
        />
        {menu?.children?.map((item) => {
          return (
            <div
              className={`menu-bar__menu-list__submenu__list--container ${
                !item.active && 'disabled'
              }`}
              key={item.id}
              onClick={() => {
                item.url && navigate(item.url);
              }}
            >
              <li
                onClick={() => {
                  setVisibility(false);
                  renderSwitchAction(item.action);
                }}
              >
                <DynamicIcon
                  className='menu-bar__menu-list__submenu__icon'
                  height='1.25rem'
                  icon={item.icon}
                  width='1.25rem'
                />
                {item.name}
              </li>
            </div>
          );
        })}
      </ul>
    </div>
  );

  return (
    <div className='menu-bar__menu-list'>
      {LIST_MENU_BAR.map((item, index) => {
        return (
          <Popover
            className='menu-bar__menu-list__popover'
            content={ListSubmenu}
            key={item.id}
            onOpenChange={() => setVisibility((visibility) => !visibility)}
            placement='bottomLeft'
            trigger='click'
          >
            <div
              className={`menu-bar__menu-list__li ${
                menu?.id === index + 1 && visibility ? 'selected ' : ''
              }`}
              onClick={() => setMenu(LIST_MENU_BAR[index])}
            >
              <div className='menu-bar__menu-list__li__text'>
                <p>{item.name}</p>
              </div>
            </div>
          </Popover>
        );
      })}

      <Popup
        cancelText='Close'
        className='menuBarAbout'
        footerButton={{
          rightClass: 'leavePopupParameter--btn_left',
        }}
        handler={handleAboutPopup}
        headerClose={true}
        headerText='About'
        show={popupAbout}
        submitButton={false}
      >
        <AboutModal />
      </Popup>
    </div>
  );
}
