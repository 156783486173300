import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';

/**
 * @param {object} props
 * @param {{hour: number | undefined, time: string, counts: number | undefined, counts_event: number | undefined}[]} props.data
 * @param {{main: string, secondary: string, hover: string}} props.color
 */
export const ChartHourly = (props) => {
  const { data = [], color } = props;
  const [activeHours, setActiveHours] = useState([]);
  const [restingHours, setRestingHours] = useState([]);

  useEffect(() => {
    const active = [];
    const resting = [];

    data?.sort((a, b) => a.hour - b.hour);
    data?.forEach(item => {
      if (item.time) {
        const utcFormat = item.time.split(' ').join('T') + 'Z';
        const time = new Date(utcFormat).getTime();
        const value = item.counts_event ?? item.counts;
        if (item.hour >= 6 && item.hour <= 18) {
          active.push([time, value]);
        } else {
          resting.push([time, value]);
        }
      }
    });

    setActiveHours(active);
    setRestingHours(resting);
  }, [data]);

  /**
   * @type {(Highcharts.Options)}
   */
  const options = {
    chart: {
      type: 'column',
      height: 330,
    },
    credits: {
      enabled: false,
    },
    title: false,
    exporting: {
      enabled: false // Disables the exporting menu
    },
    plotOptions: {
      column: {
        groupPadding: 0.1,
        pointPadding: 0,
        borderWidth: 0
      }
    },
    xAxis: {
      type: 'datetime',
      title: undefined,
      tickInterval: 3600 * 3000, // 1 hour in milliseconds
      labels: {
        formatter: function() {
          const hour = moment.utc(this.value).format('h');
          const ampm = moment.utc(this.value).format('a');
          return `${hour}${ampm === 'am' ? 'a' : 'p'}`;
        }
      },
      gridLineWidth: 0, // Remove vertical grid lines
      minorGridLineWidth: 0, // Optionally remove minor vertical grid lines
      tickLength: 0 // Optionally remove ticks
    },
    yAxis: {
      title: undefined
    },
    tooltip: {
      useHTML: true,
      formatter: function() {
        const hour = moment.utc(this.x).format('h');
        const ampm = moment.utc(this.x).format('a');
        return `
          <div style="border-radius: 4px;background: #FFF;border:none;">
            <span style="font-size:16px">${hour}${ampm === 'am' ? 'a' : 'p'}</span>
            <br/><span style="color: #07827C;font-size: 24px;font-weight: 700;">${this.y.toLocaleString('id-ID')}</span>
          </div>
        `;
      },
      backgroundColor: '#fff',
      borderWidth: 0,
      borderRadius: 4,
    },
    series: [{
      stacking: 'normal',
      name: 'Active Hours',
      data: activeHours,
      color: color.main,
      states: {
        hover: {
          lineWidth: 2,
          color: color.hover // Hover color
        }
      }
    }, {
      stacking: 'normal',
      name: 'Resting Hours',
      data: restingHours,
      color: color.secondary,
      states: {
        hover: {
          lineWidth: 2,
          color: color.hover // Hover color
        }
      }
    }]
  };

  return (
    <HighchartsReact
      allowChartUpdate={true}
      highcharts={Highcharts}
      options={options}
    />
  );
};
