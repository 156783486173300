import React from 'react';

import { AsmanDashboardSidebarContainer } from '@bvt-features/sub-module/asman/container/AsmanDashboardSidebar.container';
import { AsmanDashboardBodyContainer } from '@bvt-features/sub-module/asman/container/AsmanDashboardBody.container';
import { MenuBarContainerNonMap } from '@bvt-features/mainmap/menu-bar/container/MenuBarContainer';

import './AsmanDashboard.layout.scss';

export const AsmanDashboardLayout = () => {
  return (
    <React.Fragment>
      <MenuBarContainerNonMap />
      <div className='AsmanDashboardLayout__wrapper'>
        <AsmanDashboardSidebarContainer />
        <div className='AsmanDashboardLayout__body'>
          <div className='AsmanDashboardLayout__body-header'>
            Dashboard Result
          </div>
          <div className='AsmanDashboardLayout__body-result'>
            <AsmanDashboardBodyContainer />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
