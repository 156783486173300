import React from 'react';
import Page from '../../../pages/bvt';
import { MenuBarContainerNonMap } from '@bvt-features/mainmap/menu-bar/container/MenuBarContainer';
import { AuthGateContainer } from '@bvt-features/auth/container';
import { BasePageHeadTag } from '@bvt-shared/component/BasePageHeadTag';

/** @type {import('react-router').RouteObject} */
export const RoutesAppDashboard = {
  path: 'dashboard',
  element: (
    <div style={{ overflow: 'hidden' }}>
      <BasePageHeadTag title='LI :: Dashboard' />
      <MenuBarContainerNonMap />
      <AuthGateContainer />
    </div>
  ),
  children: [
    {
      index: true,
      element: <Page.PageDashboardMap />,
    },
    {
      path: 'pertamina/:uuid',
      element: (
        <React.Fragment>
          <BasePageHeadTag title='LI :: Dashboard' />
          <Page.PertaminaDashboard />
        </React.Fragment>
      ),
    }
  ],
};
