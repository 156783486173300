import { createSlice } from '@reduxjs/toolkit';
import {
  bulkDownloadResult,
  createNewTenant,
  createUpdateAsset,
  doDeleteAsset,
  doDownloadAssetList,
  doDownloadBulkResultXls,
  getDetailAsset,
  getKategoriList,
  getListAsset,
  getNewTenantList,
  getPOIListDetail,
  getRukoListDetail,
  getStatusAnalysisAsset,
  getTenantListDetail,
  removeNewTenant,
} from './asset.asyncAction';

const initialState = {
  status_LIST_ASSET: 'IDLE',
  status_DASHBOARD_RESULT: 'IDLE',
  status_DETAIL_ASSET: 'IDLE',
  status_DOWNLOAD: 'IDLE',
  status_BULK_RESULT_DOWNLOAD: 'IDLE',
  status_DO_BULK_DONLOAD_XLSX: 'IDLE',
  status_ADD_EDIT: 'IDLE',
  status_DELETE_ASSET: 'IDLE',
  status_RUKO_LIST: 'IDLE',
  status_TENANT_LIST: 'IDLE',
  status_POI_LIST: 'IDLE',
  status_ANALYSIS: 'IDLE',
  status_CATEGORY: 'IDLE',
  status_LIST_NEW_TENANTS: 'IDLE',
  status_CREATE_NEW_TENANTS: 'IDLE',
  status_DELETE_NEW_TENANTS: 'IDLE',
  description: '',
  message: '',
  data: {
    list: {
      data: [],
      toggleStatus: false,
      statusList: [],
      selectedID: '',
      selectedImg: '',
      coordinate: {
        lat: '',
        lng: '',
      },
      download: {
        url: '',
        filename: '',
        progress: null,
        totalSize: null,
        loadedSize: null,
        start: false,
        show: true,
        loading: false,
      },
      edit: {},
      category: [],
    },
    detail: {
      data: {},
      ruko: [],
      tenant: [],
      poi: [],
    },
    dashboard: {},
    tenants: [],
  },
};
export const assetSlice = createSlice({
  name: 'LI/PERTAMINA/ASSET',
  initialState,
  reducers: {
    resetState: (state) => {
      state.status_LIST_ASSET = 'IDLE';
      state.status_DASHBOARD_RESULT = 'IDLE';
      state.status_DETAIL_ASSET = 'IDLE';
      state.status_DOWNLOAD = 'IDLE';
      state.status_ADD_EDIT = 'IDLE';
      state.status_DELETE_ASSET = 'IDLE';
      state.status_RUKO_LIST = 'IDLE';
      state.status_TENANT_LIST = 'IDLE';
      state.status_POI_LIST = 'IDLE';
      state.status_ANALYSIS = 'IDLE';
      state.status_CATEGORY = 'IDLE';
      state.status_LIST_NEW_TENANTS = 'IDLE';
      state.status_CREATE_NEW_TENANTS = 'IDLE';
      state.status_DELETE_NEW_TENANTS = 'IDLE';
      state.description = '';
      state.message = '';
      state.data.list.data = [];
      state.data.list.toggleStatus = false;
      state.data.list.statusList = [];
      state.data.list.selectedID = '';
      state.data.list.selectedImg = '';
      state.data.list.coordinate = {
        lat: '',
        lng: '',
      };
      state.data.list.edit = {};
      state.data.list.category = [];
      state.data.detail = initialState.data.detail;
      state.data.dashboard = {};
      state.data.tenants = [];
    },
    resetDashboard: (state) => {
      state.status_DASHBOARD_RESULT = 'IDLE';
      state.data.dashboard = {};
    },
    resetStatus: (state, action) => {
      const { payload } = action;
      state.message = '';
      state.description = '';

      switch (payload.type) {
        case 'status_DASHBOARD_RESULT':
          state.status_DASHBOARD_RESULT = 'IDLE';
          break;
        case 'status_LIST_ASSET':
          state.status_LIST_ASSET = 'IDLE';
          break;
        case 'status_ADD_EDIT':
          state.status_ADD_EDIT = 'IDLE';
          break;
        case 'status_BULK_RESULT_DOWNLOAD':
          state.status_BULK_RESULT_DOWNLOAD = 'IDLE';
          break;
        case 'status_DOWNLOAD':
          state.status_DOWNLOAD = 'IDLE';
          break;
        default:
          state.status_DASHBOARD_RESULT = 'IDLE';
          state.status_LIST_ASSET = 'IDLE';
          break;
      }
      return state;
    },
    setSelectedID: (state, action) => {
      state.data.list.selectedID = action.payload.id;
    },
    setSelectedImg: (state, action) => {
      state.data.list.selectedImg = action.payload.img;
    },
    setCoordinate: (state, action) => {
      state.data.list.coordinate = action.payload.coordinate;
    },
    setDownloadProgress: (state, { payload }) => {
      state.data.list.download.progress = payload;
    },
    setDownloadTotalSize: (state, { payload }) => {
      state.data.list.download.totalSize = payload;
    },
    setDownloadLoadedSize: (state, { payload }) => {
      state.data.list.download.loadedSize = payload;
    },
    setStartDownload: (state, { payload }) => {
      state.data.list.download.start = payload;
    },
    setEditData: (state, { payload }) => {
      state.data.list.edit = payload;
    },
    setToggleStatus: (state, { payload }) => {
      state.data.list.toggleStatus = payload;
    },
    resetDownload: (state) => {
      state.data.list.download = initialState.data.list.download;
    },
    setShowDownload: (state, { payload }) => {
      state.data.list.download.show = payload;
    },
    setLoadingDownload: (state, { payload }) => {
      state.data.list.download.loading = payload;
    },
  },
  extraReducers: (builder) =>
    builder
      // LIST
      .addCase(getListAsset.pending, (state) => {
        state.status_LIST_ASSET = 'LOADING';
      })
      .addCase(getListAsset.fulfilled, (state, action) => {
        state.status_LIST_ASSET = 'SUCCESS';
        state.data.list.data = action.payload.data;
      })
      .addCase(getListAsset.rejected, (state, action) => {
        state.status_LIST_ASSET = 'FAILED';
        state.description = action.error.message;
        state.message = action.error.message;
      })
      // Detail
      .addCase(getDetailAsset.pending, (state) => {
        state.status_DETAIL_ASSET = 'LOADING';
      })
      .addCase(getDetailAsset.fulfilled, (state, action) => {
        state.status_DETAIL_ASSET = 'SUCCESS';
        state.data.detail.data = action.payload.data;
      })
      .addCase(getDetailAsset.rejected, (state, action) => {
        state.status_DETAIL_ASSET = 'FAILED';
        state.description = action.error.message;
        state.message = action.error.message;
      })
      // Download
      .addCase(doDownloadAssetList.pending, (state) => {
        state.status_DOWNLOAD = 'LOADING';
      })
      .addCase(doDownloadAssetList.fulfilled, (state, { payload }) => {
        state.data.list.download.filename = payload.filename;
        state.data.list.download.url = payload.url;
        state.data.list.download.progress = 100;
        state.status_DOWNLOAD = 'SUCCESS';
      })
      .addCase(doDownloadAssetList.rejected, (state, action) => {
        state.status_DOWNLOAD = 'FAILED';
        state.description = action.error.message;
        state.message = action.error.message;
      })
      .addCase(doDeleteAsset.pending, (state) => {
        state.status_DELETE_ASSET = 'LOADING';
      })
      .addCase(doDeleteAsset.fulfilled, (state) => {
        state.status_DELETE_ASSET = 'SUCCESS';
      })
      .addCase(doDeleteAsset.rejected, (state, action) => {
        state.status_DELETE_ASSET = 'FAILED';
        state.description = action.error.message;
        state.message = action.error.message;
      })
      // Add Edit
      .addCase(createUpdateAsset.pending, (state) => {
        state.status_ADD_EDIT = 'LOADING';
      })
      .addCase(createUpdateAsset.fulfilled, (state) => {
        state.status_ADD_EDIT = 'SUCCESS';
      })
      .addCase(createUpdateAsset.rejected, (state, action) => {
        state.status_ADD_EDIT = 'FAILED';
        state.description = action.payload.detail;
        state.message = action.payload.message;
      })
      .addCase(getRukoListDetail.pending, (state) => {
        state.status_RUKO_LIST = 'LOADING';
      })
      .addCase(getRukoListDetail.fulfilled, (state, { payload }) => {
        state.status_RUKO_LIST = 'SUCCESS';
        state.data.detail.ruko = payload.data;
      })
      .addCase(getRukoListDetail.rejected, (state, action) => {
        state.status_RUKO_LIST = 'FAILED';
        state.description = action.error.message;
        state.message = action.error.message;
      })
      .addCase(getTenantListDetail.pending, (state) => {
        state.status_TENANT_LIST = 'LOADING';
      })
      .addCase(getTenantListDetail.fulfilled, (state, { payload }) => {
        state.status_TENANT_LIST = 'SUCCESS';
        state.data.detail.tenant = payload.data;
      })
      .addCase(getTenantListDetail.rejected, (state, action) => {
        state.status_TENANT_LIST = 'FAILED';
        state.description = action.error.message;
        state.message = action.error.message;
      })
      .addCase(getPOIListDetail.pending, (state) => {
        state.status_POI_LIST = 'LOADING';
      })
      .addCase(getPOIListDetail.fulfilled, (state, { payload }) => {
        state.status_POI_LIST = 'SUCCESS';
        state.data.detail.poi = payload.data;
      })
      .addCase(getPOIListDetail.rejected, (state, action) => {
        state.status_POI_LIST = 'FAILED';
        state.description = action.error.message;
        state.message = action.error.message;
      })
      .addCase(getStatusAnalysisAsset.pending, (state) => {
        state.status_ANALYSIS = 'LOADING';
      })
      .addCase(getStatusAnalysisAsset.fulfilled, (state, { payload }) => {
        state.status_ANALYSIS = 'SUCCESS';
        state.data.list.statusList = payload.data;
      })
      .addCase(getStatusAnalysisAsset.rejected, (state, action) => {
        state.status_ANALYSIS = 'FAILED';
        state.description = action.error.message;
        state.message = action.error.message;
      })
      .addCase(getKategoriList.pending, (state) => {
        state.status_CATEGORY = 'LOADING';
      })
      .addCase(getKategoriList.fulfilled, (state, { payload }) => {
        state.status_CATEGORY = 'SUCCESS';
        state.data.list.category = payload.results;
      })
      .addCase(getKategoriList.rejected, (state, action) => {
        state.status_CATEGORY = 'FAILED';
        state.description = action.error.message;
        state.message = action.error.message;
      })
      // GET NEW TENANTS
      .addCase(getNewTenantList.pending, (state) => {
        state.status_LIST_NEW_TENANTS = 'LOADING';
      })
      .addCase(getNewTenantList.fulfilled, (state, { payload }) => {
        state.status_LIST_NEW_TENANTS = 'SUCCESS';
        state.data.tenants = payload.results;
      })
      .addCase(getNewTenantList.rejected, (state, action) => {
        state.status_LIST_NEW_TENANTS = 'FAILED';
        state.description = action.error.message;
        state.message = action.error.message;
      })
      // REMOVE NEW TENANTS
      .addCase(removeNewTenant.pending, (state) => {
        state.status_DELETE_NEW_TENANTS = 'LOADING';
      })
      .addCase(removeNewTenant.fulfilled, (state) => {
        state.status_DELETE_NEW_TENANTS = 'SUCCESS';
      })
      .addCase(removeNewTenant.rejected, (state, action) => {
        state.status_DELETE_NEW_TENANTS = 'FAILED';
        state.description = action.error.message;
        state.message = action.error.message;
      })
      // CREATE NEW TENANTS
      .addCase(createNewTenant.pending, (state) => {
        state.status_CREATE_NEW_TENANTS = 'LOADING';
      })
      .addCase(createNewTenant.fulfilled, (state) => {
        state.status_CREATE_NEW_TENANTS = 'SUCCESS';
      })
      .addCase(createNewTenant.rejected, (state, action) => {
        state.status_CREATE_NEW_TENANTS = 'FAILED';
        state.description = action.error.message;
        state.message = action.error.message;
      })
      .addCase(bulkDownloadResult.pending, (state) => {
        state.status_BULK_RESULT_DOWNLOAD = 'LOADING';
      })
      .addCase(bulkDownloadResult.fulfilled, (state, { payload }) => {
        state.data.list.download.url = payload.url;
        state.data.list.download.progress = 100;
        state.data.list.download.filename = payload.filename;
        state.status_BULK_RESULT_DOWNLOAD = 'SUCCESS';
      })
      .addCase(bulkDownloadResult.rejected, (state, action) => {
        state.status_BULK_RESULT_DOWNLOAD = 'FAILED';
        state.description = action.payload.message;
        state.message = action.payload.message;
      })
      .addCase(doDownloadBulkResultXls.pending, (state) => {
        state.status_DO_BULK_DONLOAD_XLSX = 'LOADING';
      })
      .addCase(doDownloadBulkResultXls.fulfilled, (state) => {
        state.status_DO_BULK_DONLOAD_XLSX = 'SUCCESS';
      })
      .addCase(doDownloadBulkResultXls.rejected, (state, action) => {
        state.status_DO_BULK_DONLOAD_XLSX = 'FAILED';
        state.description = action.error.message;
        state.message = action.error.message;
      }),
});

export const { reducer: assetReducer, actions: assetActions } = assetSlice;
