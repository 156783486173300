import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import MarkerCluster from '@bvt-features/pertamina/components/MarkerCluster';
import { useMainMap } from '@bvt-features/mainmap/mainmap/hook/useMainMap';
import { useModuleBar } from '@bvt-features/mainmap/module-bar/hook';
import { usePertaminaAsset } from '@bvt-features/pertamina/hooks/usePertaminaAsset';

export const PertaminaMarkerGeojsonContainer = () => {
  const initialMap = useMap();
  const mainMapp = useMainMap();
  const modulebar = useModuleBar();
  const asset = usePertaminaAsset();
  const list = asset.state.data.list;
  const coordinate = list.coordinate;
  const data = list.data;

  useEffect(() => {
    if (coordinate.lat && coordinate.lng) {
      initialMap?.setView([coordinate.lat, coordinate.lng], 18);
    }
  }, [coordinate]);

  if (initialMap && modulebar.state.submenu_selected?.code === 'asset-management-pertamina') {
    return (
      <MarkerCluster
        data={data?.filter(v => v.status === 'Success')}
        onClickMarker={(id, img) => {
          mainMapp.setActiveMenu('DETAIL_ASMAN_PERTAMINA');
          asset.setSelectedID({ id });
          asset.setSelectedImg({ img });
        }}
        opacity={1}
      />
    );
  }

  return null;
};
