/**
 * Get Icon From bvartabucket
 * @param {'poi' | 'thematic'} iconType poi or thematic
 * @param {string} iconName e.g. /Category/2.svg
 * @returns string URL
 */

import { generateUUID } from '@bvt-utils';

export const getIcon = (iconType, iconName) => {
  const BVARTA_BUCKET_URL = process.env.BVARTA_BUCKET_URL;
  const PATH_ICON_POI_V3 = process.env.PATH_ICON_POI_V3;
  const PATH_ICON_THEMATIC_V3 = process.env.PATH_ICON_THEMATIC_V3;
  const TOKEN_BUCKET = process.env.TOKEN_BUCKET;
  const BVARTA_CLIENT_BUCKET = process.env.BVARTA_CLIENT_BUCKET;
  const PATH_PERTAMINA = process.env.PATH_PERTAMINA;
  const TOKEN_CLIENT_BUCKET = process.env.TOKEN_CLIENT_BUCKET;

  if (
    !BVARTA_BUCKET_URL
      || !PATH_ICON_POI_V3
      || !PATH_ICON_THEMATIC_V3
      || !TOKEN_BUCKET
      || !iconName
  ) {
    return '';
  }

  const basePaths = {
    poi: `${BVARTA_BUCKET_URL}/${PATH_ICON_POI_V3}`,
    thematic: `${BVARTA_BUCKET_URL}/${PATH_ICON_THEMATIC_V3}`,
    pertamina: BVARTA_CLIENT_BUCKET && PATH_PERTAMINA && TOKEN_CLIENT_BUCKET
      ? `${BVARTA_CLIENT_BUCKET}/${PATH_PERTAMINA}/`
      : '',
  };

  const tokenParams = {
    poi: TOKEN_BUCKET,
    thematic: TOKEN_BUCKET,
    pertamina: TOKEN_CLIENT_BUCKET + `&uuid=${generateUUID()}`,
  };

  const basePath = basePaths[iconType];
  const token = tokenParams[iconType];

  return basePath && token ? `${basePath}${iconName}?${token}` : '';
};

const getSize = (size) => {
  return size ? parseInt(size, 10) : 200;
};

// handle cors for jspdf-autotable
export function svgToDataUrl(svgUrl, multipleSize = 1, margin = 0, fill = '') {
  return new Promise(function (resolve, reject) {
    fetch(svgUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch SVG file');
        }
        return response.text();
      })
      .then(svgText => {
        // can use the domUrl function from the browser
        const domUrl = window.URL || window.webkitURL || window;
        if (!domUrl) {
          throw new Error('(browser doesn\'t support this)');
        }

        // figure out the height and width from svg text
        let match = svgText.match(/height="(\d+)/m);
        let height = getSize(match?.[1]) * multipleSize;
        match = svgText.match(/width="(\d+)/m);
        let width = getSize(match?.[1]) * multipleSize;
        margin = margin || 0;

        // it needs a namespace
        if (!svgText.match(/xmlns="/mi)) {
          svgText = svgText.replace('<svg ', '<svg xmlns="http://www.w3.org/2000/svg" ');
        }

        // create a canvas element to pass through
        let canvas = document.createElement('canvas');
        canvas.width = width + margin * 2;
        canvas.height = height + margin * 2;
        const ctx = canvas.getContext('2d');

        // make a blob from the svg
        const svgBlob = new Blob([svgText], {
          type: 'image/svg+xml;charset=utf-8'
        });

        // create a dom object for that image
        const url = domUrl.createObjectURL(svgBlob);

        // create a new image to hold it the converted type
        const img = new Image();

        // when the image is loaded we can get it as base64 url
        img.onload = function () {
          // draw it to the canvas
          ctx.drawImage(this, margin, margin);

          // if it needs some styling, we need a new canvas
          if (fill) {
            const styled = document.createElement('canvas');
            styled.width = canvas.width;
            styled.height = canvas.height;
            const styledCtx = styled.getContext('2d');
            styledCtx.save();
            styledCtx.fillStyle = fill;
            styledCtx.fillRect(0, 0, canvas.width, canvas.height);
            styledCtx.strokeRect(0, 0, canvas.width, canvas.height);
            styledCtx.restore();
            styledCtx.drawImage(canvas, 0, 0);
            canvas = styled;
          }
          // we don't need the original anymore
          domUrl.revokeObjectURL(url);
          // now we can resolve the promise, passing the base64 url
          resolve(canvas.toDataURL('image/png', 1.0));
        };

        // load the image
        img.src = url;
      })
      .catch(err => {
        reject(new Error('failed to convert svg to png ' + err));
      });
  });
}

export async function getImageDataUrl(url) {
  try {
    // Fetch the image as a Blob
    const response = await fetch(url);

    // Check if the response is successful
    if (!response.ok) {
      throw new Error('Failed to fetch the image');
    }

    // Convert the response to a Blob
    const blob = await response.blob();

    // Create an image element
    const img = new Image();

    // Create a Promise to wait for the image to load
    return new Promise((resolve, reject) => {
      img.onload = () => {
        // Create a canvas and draw the image onto it
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Set the canvas size to the image size
        canvas.width = img.width;
        canvas.height = img.height;

        // Draw the image on the canvas
        ctx.drawImage(img, 0, 0);

        // Get the Data URL from the canvas
        const dataUrl = canvas.toDataURL('image/png', 1.0);

        // Resolve with the Data URL
        resolve(dataUrl);
      };

      img.onerror = reject;

      // Set the image source to the Blob URL (we use URL.createObjectURL)
      img.src = URL.createObjectURL(blob);
    });
  } catch (error) {
    console.error(error);
    return null; // Return null if an error occurs
  }
}
