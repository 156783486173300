import React, { Fragment, useMemo, useRef } from 'react';
import L from 'leaflet';
import * as Leaflet from 'react-leaflet';
import PropTypes from 'prop-types';
import { renderToStaticMarkup } from 'react-dom/server';
import { Spin } from 'antd';
import IconPertamina from '@bvt-features/pertamina/assets/new-marker.png';
import IconUnderConstractionPertamina from '@bvt-features/pertamina/assets/under_constraction_marker.png';

export function Marker(props) {
  const {
    children,
    draggable,
    position,
    setPosition,
    loading,
    iconName,
  } = props;
  const markerRef = useRef(null);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker !== null) {
          setPosition && setPosition(marker.getLatLng());
        }
      },
    }),
    []
  );

  const markerLoading = new L.DivIcon({
    html: renderToStaticMarkup(<Spin />),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [8, -34],
    shadowSize: [41, 41],
  });

  const markerPertamina = L.icon({
    iconUrl: iconName === 'new-asset' ? IconPertamina : IconUnderConstractionPertamina,
    iconSize: [36, 42],
    iconAnchor: [18, 40],
  });

  const iconPertamina = loading ? markerLoading : markerPertamina;

  return (
    <Fragment>
      {position && (
        <Leaflet.Marker
          draggable={draggable}
          eventHandlers={eventHandlers}
          icon={iconPertamina}
          position={position}
          ref={markerRef}
        >
          {!!children && (
            <Leaflet.Popup closeButton={false}>{children}</Leaflet.Popup>
          )}
        </Leaflet.Marker>
      )}
    </Fragment>
  );
}

Marker.defaultProps = {
  children: null,
  colorIcon: 'currentColor',
  draggable: false,
  iconName: 'DefaultPoi',
  position: null,
};

Marker.propTypes = {
  children: PropTypes.node,
  colorIcon: PropTypes.string,
  draggable: PropTypes.bool,
  iconName: PropTypes.string,
  position: PropTypes.array,
  setPosition: PropTypes.func,
};
