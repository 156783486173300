import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';

/**
 * @param {object} props
 * @param {{hour: number | undefined, time: string, counts: number | undefined, counts_event: number | undefined}[]} props.data
 * @param {{main: string, secondary: string, hover: color}} props.color
 */
export const ChartDaily = (props) => {
  const { data = [], color } = props;
  const [weekdays, setWeekdays] = useState([]);
  const [weekends, setWeekends] = useState([]);

  useEffect(() => {
    const weekdaysArr = [];
    const weekendsArr = [];

    const newData = [ ...data ];
    newData?.sort((a, b) => new Date(a.time) - new Date(b.time));
    newData.forEach(item => {
      const day = moment(item.time).day();
      const timestamp = new Date(item.time).getTime();
      const value = item.counts_event ?? item.counts;
      if (day === 0 || day === 6) {
        weekendsArr.push([timestamp, value]);
      } else {
        weekdaysArr.push([timestamp, value]);
      }
    });

    setWeekdays(weekdaysArr);
    setWeekends(weekendsArr);
  }, [data]);

  /**
   * @type {(Highcharts.Options)}
   */
  const options = {
    chart: {
      type: 'column',
      height: 330,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        groupPadding: 0.1,
        pointPadding: 0,
        borderWidth: 0
      }
    },
    title: false,
    exporting: {
      enabled: false // Disables the exporting menu
    },
    xAxis: {
      type: 'datetime',
      title: undefined,
      labels: {
        formatter: function () {
          const date = new Date(this.value);
          const day = date.getDate();
          if ([1, 5, 10, 15, 20, 25, 30].includes(day)) {
            return day;
          }
          return '';
        },
        step: 1,
      },
      tickInterval: 24 * 3600 * 1000,
      gridLineWidth: 0, // Remove vertical grid lines
      minorGridLineWidth: 0, // Optionally remove minor vertical grid lines
      tickLength: 0 // Optionally remove ticks
    },
    yAxis: {
      title: undefined
    },
    tooltip: {
      useHTML: true,
      formatter: function() {
        return `
          <div style="border-radius: 4px;background: #FFF;border:none;">
            <span style="font-size:16px">${moment(this.x).format('DD MMMM')}</span>
            <br/><span style="color: #07827C;font-size: 24px;font-weight: 700;">${this.y.toLocaleString('id-ID')}</span>
          </div>
        `;
      },
      backgroundColor: '#fff',
      borderWidth: 0,
      borderRadius: 4,
    },
    series: [{
      stacking: 'normal',
      name: 'Weekdays',
      data: weekdays,
      color: color.main,
      states: {
        hover: {
          lineWidth: 2,
          color: color.hover // Hover color
        }
      }
    }, {
      stacking: 'normal',
      name: 'Weekends',
      data: weekends,
      color: color.secondary,
      states: {
        hover: {
          lineWidth: 2,
          color: color.hover // Hover color
        }
      }
    }]
  };

  return (
    <HighchartsReact
      allowChartUpdate={true}
      highcharts={Highcharts}
      options={options}
    />
  );
};
