import React, { useEffect } from 'react';
import './PertaminaDownloadNotificationContainer.scss';
import { Progress } from 'antd';
import { usePertaminaAsset } from '@bvt-features/pertamina/hooks/usePertaminaAsset';
import { CloseOutlined } from '@ant-design/icons';

export const PertaminaDownloadNotificationContainer = () => {
  const asset = usePertaminaAsset();
  const { progress, loadedSize, totalSize, filename, show, start } =
    asset.state.data.list.download;
  const convertBit = (value) => {
    if (!value) {
      return '-';
    }
    let size = value / 1024;
    let type = 'KB';
    if (size > 1024) {
      size = size / 1024;
      type = 'MB';
    }
    return `${(size - size * 0.235).toFixed(2)}${type}`;
  };

  useEffect(() => {
    const body = document.querySelector('body');
    if (!show) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = 'auto';
    }
  }, [show]);

  useEffect(() => {
    return () => {
      const body = document.querySelector('body');
      body.style.overflow = 'auto';
    };
  }, []);

  return (
    <>
      {start && (
        <div
          className='feature-pertamina PertaminaDownloadNotification'
          style={{
            transform: `translateX(${show ? 0 : '16.75rem'})`,
            transition: '0.25s ease',
            boxShadow: `${
              show ? '-16px 15px 27px -2px rgba(0,0,0,0.25)' : 'none'
            }`,
          }}
        >
          <div className='container'>
            <div className='show-hide'>
              <button
                className='show-hide-button'
                onClick={() => asset.setShowDownload(!show)}
              >
                <svg
                  height='0.75rem'
                  style={{
                    transform: `rotate(${!show ? 0 : 180}deg)`,
                  }}
                  viewBox='0 0 19 14.5'
                  width='0.75rem'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <g
                    data-name='Group 11055'
                    id='Group_11055'
                    transform='translate(-9 36) rotate(-90)'
                  >
                    <path
                      d='M4.763.8A1,1,0,0,1,6.237.8l3.227,3.52A1,1,0,0,1,8.727,6H2.273a1,1,0,0,1-.737-1.676Z'
                      data-name='Polygon 134'
                      fill='currentColor'
                      id='Polygon_134'
                      transform='translate(21.5 23.5) rotate(-90)'
                    />
                    <rect
                      data-name='Rectangle 4785'
                      fill='currentColor'
                      height='19'
                      id='Rectangle_4785'
                      rx='1'
                      transform='translate(32 9)'
                      width='4'
                    />
                  </g>
                </svg>
              </button>
            </div>
            <div className='header'>
              <span>Download</span>
              {progress === 100 && (
                <button
                  onClick={() => {
                    asset.setStartDownload(false);
                    asset.resetDownload();
                  }}
                >
                  <CloseOutlined />
                </button>
              )}
            </div>
            <div className='content'>
              <div className='item'>
                <span className='name'>{filename || '-'}</span>
                <span className='persen'>{progress ?? '-'}%</span>
              </div>
              <Progress
                percent={totalSize ? progress : 100}
                showInfo={false}
                status={totalSize ? 'normal' : 'active'}
                strokeColor={'#05A961'}
              />
              <span className='size'>
                {convertBit(loadedSize)} of {convertBit(totalSize)}
              </span>
              <span className='information'>Please do not close this page</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
