import Resource from '../../../../api/resource';

export class KategoriService extends Resource {
  constructor() {
    super('kategori', '/api', 'api-poi', 'v2');
  }
  getAll() {
    this.setAdditionUri('/v3/all');
    return this.list();
  }
}
