import { useAuth } from '@bvt-features/auth/hooks';
import React, { useEffect, useState } from 'react';
import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import socket from '../../../../utils/socket';
import { ForceLogout } from '@bvt-features/auth/component';
import { usePertaminaAsset } from '@bvt-features/pertamina/hooks/usePertaminaAsset';

/**
 *
 */
export default function AuthGateContainer() {
  const [isOpen, setOpen] = useState(false);
  const auth = useAuth();
  const pertamina = usePertaminaAsset();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.state?.data?.user?.id) {
      socket.connect();
      const dualLoginEvent = 'dual-login-detected-' + auth.state.data.user?.id;
      const notificationEvent = `notification-private-${auth.state.data?.user?.id}-pertamina`;

      socket.off(dualLoginEvent);
      socket.off(notificationEvent);

      socket.on(dualLoginEvent, () => {
        setOpen(true);
        auth.doLogout();
      });
      socket.on(notificationEvent, ({ payload }) => {
        pertamina.setStartDownload(true);
        pertamina.doDownloadBulkResult({
          url: payload.download_url,
          filename: payload.filename,
          status: payload.status,
          message: payload.message,
        });
      });
    }
    return () => {
      socket.disconnect();
    };
  }, [auth.state?.data?.user]);

  return (
    <>
      {auth.state.data.isAuthenticated &&
      auth.state.data.lokasiintelligenceAccess ? (
          <Outlet />
        ) : (
          <Navigate to={'/401'} />
        )}
      <ForceLogout onSubmit={() => navigate('/auth/logout')} open={isOpen} />
    </>
  );
}
