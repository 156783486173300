import { ID_SOURCE_CONSTANT } from '@bvt-shared/constant/ID_SOURCE_CONSTANT';
import React, { Fragment, useEffect } from 'react';
import { LayerGroup, useMap } from 'react-leaflet';
import { usePertaminaDroppableMarker } from '@bvt-features/pertamina/hooks/usePertaminaDroppableMarker/usePertaminaDroppableMarker';
import { Marker } from '@bvt-features/pertamina/components/Marker';

export function PertaminaDroppableMarker() {
  const initialMap = useMap();
  const droppable_marker = usePertaminaDroppableMarker();

  const updateMapView = (activeMarkers) => {
    if (activeMarkers.length > 0) {
      const activeMarker = activeMarkers[activeMarkers.length - 1];
      initialMap?.setView([activeMarker?.latitude, activeMarker?.longitude], 15);
    }
  };

  useEffect(() => {
    const point = droppable_marker.state.point;
    if (point) {
      const coordinate = initialMap.containerPointToLatLng(point);
      droppable_marker.setCoordinate([
        coordinate?.lat?.toString(),
        coordinate?.lng?.toString(),
      ]);
    }
  }, [droppable_marker.state.point]);

  useEffect(() => {
    const coordinate = droppable_marker.state.coordinate;
    if (coordinate?.length === 2) {
      droppable_marker.checkIfAreaIsValid({
        id_source: ID_SOURCE_CONSTANT,
        latitude: coordinate?.[0],
        longitude: coordinate?.[1],
      });
    }
  }, [droppable_marker.state.coordinate]);

  useEffect(() => {
    const histories = droppable_marker.state?.historyList;
    const index = droppable_marker.state?.activeMarkerHistoryIndex || 0;
    if (histories?.length > 0) {
      const history = histories?.[index];
      initialMap?.setView([history?.lat, history?.long], 15);
    }
  }, [
    droppable_marker.state?.historyList,
    droppable_marker.state?.activeMarkerHistoryIndex
  ]);

  useEffect(() => {
    updateMapView(droppable_marker.state?.activeMarkers || []);
  }, [
    droppable_marker.state?.activeMarkers,
    droppable_marker.state?.activeMarkersRight,
  ]);

  const renderComponent = () => {
    return (
      <LayerGroup>
        <div
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          style={{ width: '100%', height: '100%', zIndex: 1000 }}
        >
          {droppable_marker.state?.historyList?.length > 0 && (
            <Marker
              children={droppable_marker?.siteName || ''}
              colorIcon={droppable_marker.state?.marker_color}
              draggable={droppable_marker.state?.draggable}
              iconName={droppable_marker.state?.icon}
              key={droppable_marker?.id || 1}
              loading={droppable_marker.state?.status_PLACE === 'LOADING'}
              position={[
                droppable_marker.state?.historyList[
                  droppable_marker.state?.activeMarkerHistoryIndex
                ]?.lat,
                droppable_marker.state?.historyList[
                  droppable_marker.state?.activeMarkerHistoryIndex
                ]?.long,
              ]}
              setPosition={(e) => {
                droppable_marker.checkIfAreaIsValid({
                  id_source: ID_SOURCE_CONSTANT,
                  latitude: e?.lat?.toString(),
                  longitude: e?.lng?.toString(),
                });
              }}
            />
          )}
        </div>
      </LayerGroup>
    );
  };

  return <Fragment>{initialMap ? renderComponent() : null}</Fragment>;
}
