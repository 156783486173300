import React from 'react';
import './TableRecommendation.scss';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { NoContent } from '../NoContent/NoContent';

/**
 * @param {object} props
 * @param {boolean} props.isTitle
 * @param {'Red Ocean' | 'Blue Ocean'} props.type
 * @param {Array<object>} props.data
 * @param {Array<object>} props.columns
 * @param {string} props.note
 * @returns
 */

export const TableRecommendation = ({
  isTitle = false,
  type,
  data,
  columns,
  note,
}) => {
  const title = `${type} Recommendation`;
  return (
    <div className='PertaminaTableRecomendation'>
      {isTitle && <span className='title'>{title}</span>}
      {data.length ? (
        <>
          <Table
            bordered
            className='table'
            columns={columns}
            dataSource={data}
            pagination={false}
            scroll={{ x: 600, y: 400 }}
          />
          <div className='notes'>
            <span className='description'>
              <div dangerouslySetInnerHTML={{ __html: note }} />
            </span>
          </div>
        </>
      ) : (
        <NoContent />
      )}
    </div>
  );
};

TableRecommendation.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  isTitle: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['Red Ocean', 'Blue Ocean']).isRequired,
};
